@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap');

div[name="tacos"] {
    div.card {
        background-color: #f5fcff;
        font-family: 'Comic Neue', cursive;
        margin: 10px;
    }
}

.page-title {
    @media (max-width: 399px) {
        font-size: 26px;
    }
}