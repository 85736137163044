.blog-category {
    color:white !important;
    background-color: $royal-blue;
    border-radius: 10px;
    p {
        color: lightgrey !important;
    }
}

.blog-subtitle__mobile {
    font-size: 12px;
}