//body-font
$body-font-url : 'https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&display=swap';
$body-font-family : 'Open Sans Condensed', serif;

//body-color
//$elephant-color : #004259;
$elephant-color : #235A8C;

//#004259

//logo-color
$royal-blue : #002366; 

//logo
$logo-font-url : 'https://fonts.googleapis.com/css2?family=Unica+One&display=swap';
$logo-font-family : 'Unica One', cursive;
