.intro-content {
    flex-direction: column;    
    margin: auto;
    text-align: center;
    h1 {
        font-size: 1.875rem;
    }
    p {
        font-size: 1rem;
    }
    @media (min-width:45rem) {
        text-align: justify;
        h1 {
            font-size: 2.5rem;
        }
        p {
            font-size: 1.125rem;
        }
    }
}

.align-badges {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    span.badge {
        font-size: 0.85rem;
    }
    @media (min-width:75rem) {
        span.badge {
            font-size: 1rem;
        }
    }
}

// .inspired-quote {
//     font-size: 1rem;
//     div {
//         padding: 0;
//     }
// }

.avatar {
    border: none;
}