.MuiGrid-container-1 {
    display: flex;
    justify-content: space-around;
    a{
        font-size: 12px;
    }
    @media (min-width:45rem) {
        a {
            font-size: 18px;
        }
    }
}

img {
    width: 100%;
    @media (min-width:75rem) {
        width: 70%
    }
}