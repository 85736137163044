@import url($logo-font-url);

.logo-container {
    display: flex;
    justify-content: space-around;
}

.logo-container__text {
    color: $royal-blue;
    font-family: $logo-font-family;
    padding: 0 20px;
    text-decoration: none !important;
    &:hover {
        color: $royal-blue !important;
    }
    h1 {
        font-size: 24px;
    }
    span {
        font-size: 12px;
    }
    @media (min-width: 350px) {        
        h1 {
            font-size: 28px;
        }
        span {
            font-size: 14px;
        }
    }
    @media (min-width: 500px) {
        h1 {
            font-size: 32px;
        }
        span {
            font-size: 16px;
        }
    }
    @media (min-width: 750px) {
        h1 {
            font-size: 40px;
        }
        span {
            font-size: 20px;
        }
    }
}