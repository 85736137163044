p {
    font-size: 14px;
    @media (min-width:75rem) {
        font-size: 18px;
    }
}

p.text-muted {
    font-size: 12px;
    @media (min-width:75rem) {
        font-size: 16px;
    }
}