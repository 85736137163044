.testimonial-carousel {
    background-color: #eeeeee;
    //background-color: #85FFBD;
    //background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
    border-radius: 5px;
    padding: 20px 60px; 
    @media (min-width:55rem) {
        padding: 20px 80px;   
    }     
    @media (min-width:75rem) {
        padding: 20px 120px;   
    }
}

.testimonial-card {
    background: transparent;
    border: none;
}