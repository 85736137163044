@import url($body-font-url);

body {
    color: $elephant-color;
    font-family: $body-font-family;
    font-size: 1.125rem;
}

.align-center {
    display: flex;
    justify-content: space-around;
}

.color__royalblue {
    color: $royal-blue;
}

.color__elephantblue {
    color: $elephant-color;
}